
.tf-snack {
    position: fixed;
    opacity: 0.95;
    bottom: 18px;
    right: 18px;
    border-radius: 2px;
    border: 1px solid #f2f2f2;
    background: #333;
    font-size: 18px;
    color: #fcfcfc;
    min-width: 360px;
    min-height: 140px;
    padding: 0;
    z-index: 200;
  
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  .tf-snack .tf-snack-label {
    position: absolute;
    left: 18px;
    top: 18px;
    /* color: #fff; */
    color: #444;
    font-size: 22px;
    font-weight: bold;
  }
  
  .tf-snack .tf-snack-text {
    font-size: 18px;
    position: absolute;
    left: 21px;
    top: 53px;
    /* color: #eee; */
    color: #666;
  }
  
  .tf-snack .tf-snack-x {
    cursor: pointer;
    position: absolute;
    /* right: 4px; */
    right: 10px;
    /* top: -9px; */
    top: -2px;
    font-size: 24px;
    color: #f0506e;
  }
  